/* Group-14
Rajpurohit, Ravi (1002079916)
*/

/* Header and Footer */
/* Footer */
.foot footer {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    justify-content: center;
    height: 45px;
    padding: 20px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}


.instructor-ui {
    /* Group-14
Rajpurohit, Ravi (1002079916)
*/


    .header-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-left: auto;
    }

    .header-text {
        flex-grow: 1;
        /* Add this line */
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .head header {
        color: #333333;
        /* Dark Grey */
        text-shadow: 2px 2px 4px #5d5858;
        /* Text Shadow */
        display: flex;
        text-align: center;
        align-content: center;
        justify-content: center;
        padding: 20px, ;
        /* Increase padding to make them bigger */
        font-family: 'Monotype Corsiva', cursive;
        /* Custom font */
        font-size: 50px;
        /* Large font size */
        height: 100px;
        /* Increased height */
        flex-wrap: wrap;
    }

    .header {
        font-family: 'Monotype Corsiva', cursive;
        font-size: 50px;
        height: 100px;
        padding: 20px;
    }


    /* PAGE CONTAINER */
    .page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        /* Full viewport height */
    }

    /* MAIN CONTAINER */
    .main-container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        /* Horizontal layout */
        flex: 1;
        /* Take up all available space */
    }

    /* Left Navbar */
    .left-navbar {
        width: 300px;
        /* Fixed width */
        background-color: #528552;
        color: white;
        flex-shrink: 0;
        /* Don't allow it to shrink */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* Evenly space buttons */
        height: 100%;
        /* Take up all available space */
        border-radius: 20px;
    }

    /* Left Navbar */
    .left-navbar a {
        justify-content: center;
        display: flex;
        padding: 15px;
        text-align: center;
        align-content: center;
        text-decoration: none;
        color: white;
        border-bottom: 1px solid #444;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
        flex-wrap: wrap;
    }

    .left-navbar a:hover {
        background-color: #555;
    }

    .nav-button {
        flex-grow: 1;
        /* Allow buttons to grow to fill space */
        margin: 5px;
        /* Add a little margin for separation */
        padding: 10px;
        /* Add padding for better spacing */
        background-color: darkslategrey;
    }

    /* CONTENT */
    .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* Center vertically */
        height: 100%;
        /* Take full available height */
    }

    .course-box a {
        color: #2f1401;
        width: 300px;
        /* Fixed width for better look */
        height: 150px;
        /* Fixed height */
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid #000;
        border-radius: 16px;
        /* Increased rounded corners for a modern look */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        /* Professional box shadow for elevated look */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* Smooth transition for hover effect */
        margin: 2vw;
        text-decoration: none;
        opacity: 0.8;
        font-family: "Lexend Deca";
        font-weight: bolder;
        text-shadow: 1px 1px 2px;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
    }



    .course-box a:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Increased shadow on hover for interactive feel */
    }


    /* CHAT ICON */
    .chat-icon {
        position: fixed;
        /* Fixed position */
        bottom: 60px;
        /* Above the footer */
        right: 20px;
        /* Right side of the page */
        z-index: 1000;
        /* Above other elements */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* Subtle box shadow */
        border-radius: 50%;
        /* Fully rounded to make it look like an icon */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* Smooth transition for hover effect */
    }

    .chat-icon:hover {
        transform: scale(1.2);
        /* Slightly scale up on hover */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        /* Increased shadow on hover for interactive feel */
    }


    /* RESPONSIVE */
    @media only screen and (max-width: 1023px) {
        body::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            /* Situate the background behind the content */

            background-image: url('../assets/bgimg.jpg');
            background-size: cover;
            background-position: center;

            /* Make the background image lighter */
            filter: brightness(1);
        }

        .page-container {
            height: auto;
            /* Let it adjust */
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
            flex-wrap: wrap;
        }


    }


    @media (max-width: 768px) {
        body::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            /* Situate the background behind the content */

            background-image: url('../assets/bgimg.jpg');
            background-size: cover;
            background-position: center;

            /* Make the background image lighter */
            filter: brightness(1);
        }

        .main-container {
            flex-direction: column;
            /* Stack items vertically */
        }

        .left-navbar {
            width: 100%;
            box-sizing: border-box;
            /* To ensure padding doesn't affect width */
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
        }

        .course-box {
            margin-top: 8vh;
            /* Space between navbar and course boxes */
            width: 400px;
        }

        .primary-button {
            width: 100%;
            margin-top: 10px;
        }

    }

    @media only screen and (max-width: 320px) {
        .page-container {
            height: auto;
            /* Let it adjust */
        }

        .left-navbar {
            width: 100%;
        }

        .footer {
            position: static;
            bottom: auto;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
        }

    }

    @media only screen and (max-width: 320px) {
        .page-container {
            height: auto;
            /* Let it adjust */
        }

        .left-navbar {
            width: 100%;
        }

        .footer {
            position: static;
            bottom: auto;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
        }

    }

    @media only screen and (max-width: 375px) {
        .left-navbar {
            width: 100%;
        }

        .footer {
            position: static;
            bottom: auto;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
        }

        .primary-button {
            position: relative;
            margin-bottom: -20px;
        }
    }



    @media only screen and (min-width: 1024px) {
        body::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            /* Situate the background behind the content */

            background-image: url('../assets/bgimg.jpg');
            background-size: cover;
            background-position: center;

            /* Make the background image lighter */
            filter: brightness(1);
        }

        .left-navbar {
            width: 300px;
        }

        .footer {
            position: absolute;
            bottom: 0;
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            /* Center items */
            margin-left: 0;
            /* Remove extra margin */
            flex-wrap: wrap;

        }

        .primary-button {
            margin-left: 960px;
            position: relative;
            margin-bottom: -30px;
        }

        button {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.067);
            transition: 0.3s;
        }

    }

    /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */

    /* studentinfo.html STYLES */
    fieldset {
        min-width: 450px;
        border-radius: 20px;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
    }

    input {
        text-align: center;
        border-radius: 8px;
        height: 40px;
        padding-left: 30px;
        padding-right: 30px;
        font-family: "Lexend Deca";
    }

    input#male,
    input#female,
    input#other {
        height: auto;
        position: absolute;
        left: 90px;
    }

    textarea {

        border-radius: 8px;
        font-family: "Lexend Deca";
    }

    label {
        padding-left: 30px;
        padding-right: 30px;
        font-family: "Lexend Deca";
        cursor: pointer;
    }

    .gender-label {

        position: absolute;
        left: 90px;
    }

    legend,
    h2 {
        text-align: center;
        font-family: "Lexend Deca";
    }

    .readonly-list {
        background: #ffffff85;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-family: "Lexend Deca";
        width: auto;
    }

    /* remove if wrong */
    .simain-container {
        display: flex;
        flex-direction: row;
    }

    .container {
        margin-left: auto;
        /* Pushes the container to occupy remaining space */
        margin-right: auto;
        /* Centers the container */
        width: 30%;
        /* You can adjust this width */
    }

    button[type="submit"] {
        font-family: "Lexend Deca";
        background-color: #007BFF;
        /* Blue */
        color: white;
        padding: 14px 28px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s;
    }

    button[type="submit"]:hover {
        background-color: #1673d5;
        /* Darker blue */
        transform: translateY(-2px);
    }

    button[type="submit"]:active {
        background-color: #0153ab;
        /* Even darker blue */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transform: translateY(1px);
    }


    @media (max-width: 768px) {
        body::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            /* Situate the background behind the content */

            background-image: url('../assets/bgimg.jpg');
            background-size: cover;
            background-position: center;

            /* Make the background image lighter */
            filter: brightness(1);
        }

        .simain-container {
            flex-direction: column;
            /* Stack items vertically */
        }

        .left-navbar {
            width: 100%;
            box-sizing: border-box;
            /* To ensure padding doesn't affect width */
        }

        .container {
            display: flex;
            flex-direction: column;
            align-content: center;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

        }



    }

    .instruction-container {
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
    }

    /*STUDENT TABLE */
    .student-container {
        width: 100%;
        margin-right: 20px;
    }

    .course-button {
        margin-left: 100px;
    }

    .modules-container {
        width: 100%;
        margin-right: 20px;
    }

    .module-detail {
        min-width: 650px;
        background-color: #f1f1f175;
        margin: 20px 0;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        font-family: "Lexend Deca";
        transition: all 0.3s ease, box-shadow 0.3s ease;
        column-gap: 10px;
    }

    .module-detail:hover {
        transform: translateY(-10px) !important;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
        /* Increased shadow on hover for interactive feel */
    }

    .module-detail a {
        text-decoration: none;
        color: #000;
    }

    #hmodule {
        text-align: center;
        align-items: center;
    }

    button {
        justify-content: center;
        display: flex;
        padding: 15px;
        text-align: right;
        align-content: right;
        text-decoration: none;
        color: rgb(255, 255, 255);
        border-radius: 9px;
        transition: background-color 0.3s ease;
        flex-wrap: wrap;
        margin-top: 20px;
        margin: auto;
    }

    .primary-button {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
        color: rgb(0, 0, 0);
        text-align: center;
    }

    .primary-button:hover {
        background-color: #555555bb;
        color: white;
    }

    .feedback-container {
        width: 100%;
    }

    * {
        box-sizing: border-box;
    }

    input[type=text],
    select,
    textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
    }

    label {
        padding: 12px 12px 12px 0;
        display: inline-block;
    }

    input[type=submit] {
        background-color: #04AA6D;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        float: right;
    }

    input[type=submit]:hover {
        background-color: #45a049;
    }

    .container {
        border-radius: 5px;
        background-color: #f2f2f2;
        padding: 20px;
    }

    .col-25 {
        float: left;
        width: 25%;
        margin-top: 6px;
    }

    .col-75 {
        float: left;
        width: 75%;
        margin-top: 6px;
    }

    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {

        .col-25,
        .col-75,
        input[type=submit] {
            width: 100%;
            margin-top: 0;
        }
    }

    .profile_pic_update {
        width: 100%;
        margin-left: 50px;
        margin-right: 50px;
    }

}
.header-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
}

.header-text {
  flex-grow: 1;
  /* Add this line */
  display: flex;
  justify-content: center;
  align-items: center;
}

.head header {
  /* Dark Grey */
  text-shadow: 2px 2px 4px #5d5858;
  /* Text Shadow */
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: between;
  padding: 5px;
  /* Increase padding to make them bigger */
  font-family: "Monotype Corsiva", cursive;
  /* Custom font */
  font-size: 50px;
  /* Large font size */
  height: auto;
  /* Increased height */
  flex-wrap: wrap;
}

.header {
  font-family: "Monotype Corsiva", cursive;
  font-size: 50px;
  height: 100px;
  padding: 20px;
}

/* Footer */
.foot footer {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  justify-content: center;
  height: 35px;
  padding: 10px;
  font-family: "Lexend Deca";
}

.foot {
  position: relative;
  bottom: 0;
  width: 100%;
}

.global-styles-ui {
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Situate the background behind the content */

    background-image: url("../assets/bgimg.jpg") !important;
    background-size: cover;

    /* Make the background image lighter */
    filter: brightness(1);
  }

  /* Rajpurohit, Ravi (1002079916) | Khande Rao, Anirudh Sai (1002075887)
Reddi, Sai Ruthwik (1002063535) | Sayyed, Aashiq (1002082459)
Thaker, Rutva (1002121470)*/

  /* font-family: 'Monotype Corsiva', cursive; ---- for header
    font-family: "Lexend Deca";------ for text in body */

  /* PAGE CONTAINER */
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Full viewport height */
  }

  /* MAIN CONTAINER */
  .main-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    /* Horizontal layout */
    flex: 1;
    /* Take up all available space */
    align-items: center;
  }

  .actual-footer {
    background: rgb(110, 160, 230);

    width: 100%;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 999 !important;
  }

  /* Left Navbar */
  .left-navbar {
    height: fit-content;
    width: 300px;
    /* Fixed width */
    background-color: #528552;
    color: white;
    flex-shrink: 0;
    /* Don't allow it to shrink */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Evenly space buttons */
    /* Take up all available space */
    border-radius: 20px;
  }

  /* Left Navbar */
  .left-navbar a {
    font-family: "Lexend Deca";
    justify-content: center;
    display: flex;
    padding: 18px;
    text-align: center;
    align-content: center;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid #444;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    flex-wrap: wrap;
  }

  .left-navbar a:hover {
    background-color: #555;
  }

  .nav-button {
    flex-grow: 1;
    /* Allow buttons to grow to fill space */
    margin: 5px;
    /* Add a little margin for separation */
    padding: 10px;
    /* Add padding for better spacing */
    background-color: darkslategrey;
  }

  /* CONTENT */
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* Center vertically */
    height: 100%;
    /* Take full available height */
  }

  .course-box a {
    color: #000000;
    width: 300px;
    /* Fixed width for better look */
    height: 150px;
    /* Fixed height */
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #000;
    border-radius: 16px;
    /* Increased rounded corners for a modern look */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    /* Professional box shadow for elevated look */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transition for hover effect */
    margin: 2vw;
    text-decoration: none;
    opacity: 0.8;
    font-family: "Lexend Deca";
    font-weight: bolder;
    text-shadow: 1px 1px 2px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.494);
  }

  .course-box a:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.571);
    /* Increased shadow on hover for interactive feel */
  }

  /* RESPONSIVE */
  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .main-container {
      flex-direction: column;
    }

    .left-navbar {
      width: 80vw;
    }

    .content {
      flex-direction: column;
    }

    .course-box {
      margin-top: 8vh;
    }

    .course-box a {
      width: 70vw;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .main-container {
      flex-direction: column;
    }

    .left-navbar {
      width: 80vw;
    }

    .content {
      flex-direction: column;
    }

    .course-box {
      margin-top: 8vh;
    }

    .course-box a {
      width: 70vw;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
    }

    .main-container {
      flex-direction: column;
    }

    .left-navbar {
      width: 85vw;
    }

    .content {
      flex-direction: column;
    }

    .course-box {
      margin-top: 8vh;
    }

    .course-box a {
      width: 75vw;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
    }

    .main-container {
      flex-direction: column;
    }

    .left-navbar {
      width: 90vw;
    }

    .content {
      flex-direction: column;
    }

    .course-box {
      margin-top: 8vh;
    }

    .course-box a {
      width: 80vw;
    }
  }

  @media (min-width: 912px) {
    /* ... your rules ... */
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
    }

    .left-navbar {
      width: 90vw;
    }

    .content {
      flex-direction: column;
    }

    .main-container {
      flex-direction: column;
    }

    .course-box a {
      width: 90vw;
    }
  }

  /* min-width at the end */
  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .main-container {
      flex-direction: row;
      align-items: flex-start;
    }

    .left-navbar {
      height: fit-content;
      width: 300px;
    }

    .content {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .course-box {
      margin-top: auto;
      margin-bottom: auto;
    }

    .course-box a {
      width: 20vw;
    }
  }

  button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */

  /* studentinfo.html STYLES */
  #info {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .info-contact-form {
    width: auto;
  }

  @media (min-width: 1024px) {
    .contact-info {
      margin-left: auto !important;
      margin-right: auto !important;
      width: 70vw;
    }

    .info-contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30vw !important;
    }
  }

  #info input {
    text-align: center;
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
  }

  #info select {
    width: 15vw;
    text-align: center;
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
    border: 1px #000;
  }

  #info input#male,
  #info input#female,
  #info input#other {
    height: auto;
    position: absolute;
    left: 90px;
  }

  #info text {
    height: 40px;
  }

  #info textarea {
    border-radius: 8px;
    font-family: "Lexend Deca";
  }

  #info label {
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
    cursor: pointer;
  }

  .gender-label {
    position: absolute;
    left: 90px;
  }

  legend,
  h2,
  h1 {
    text-align: center;
    font-family: "Lexend Deca";
  }

  .readonly-list {
    background: #ffffff85;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: "Lexend Deca";
    width: auto;
  }

  /* remove if wrong */
  .simain-container {
    display: flex;
    flex-direction: row;
  }

  .sicontainer {
    /* max-height: 80vh;  70% of the viewport height */
    overflow-y: auto;
    margin-left: auto;
    /* Pushes the container to occupy remaining space */
    margin-right: auto;
    /* Centers the container */
    /* You can adjust this width */
  }

  .sicontainer::-webkit-scrollbar {
    width: 6px;
  }

  .sicontainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Transparent black */
    border-radius: 3px;
  }

  button[type="submit"] {
    font-family: "Lexend Deca";
    background-color: #007bff;
    /* Blue */
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin: 5px;
  }

  button[type="submit"]:hover {
    background-color: #1673d5;
    /* Darker blue */
    transform: translateY(-2px);
  }

  button[type="submit"]:active {
    background-color: #0153ab;
    /* Even darker blue */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(1px);
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .simain-container {
      flex-direction: column;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      width: 95vw;
    }

    .sicontainer {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .simain-container {
      flex-direction: column;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      width: 95vw;
    }

    .sicontainer {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .simain-container {
      flex-direction: column;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      width: 95vw;
    }

    .sicontainer {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;

      /* Make the background image lighter */
      filter: brightness(1);
    }

    .simain-container {
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      width: 95vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .container {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 90%;
      overflow-y: visible;
      max-height: none;
    }

    fieldset {
      /* Override the min-width */
      width: auto;
      /* Take full width */
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .simain-container {
      flex-direction: column;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      width: 95vw;
    }

    .sicontainer {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  /* min-width at the end */
  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .sipage-container {
      flex-direction: column;
    }

    .simain-container {
      flex-direction: row;
    }

    .left-navbar {
      margin-left: 0.5vw;
      height: fit-content;
      width: 300px;
      align-self: flex-start;
    }

    .sicontainer {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* studentexams.html STYLES */
  /* New professional styles for Exams and Assignments */
  .sepage-container {
    display: flex;
    flex-direction: column;
  }

  #exams,
  #assignments {
    max-height: 65vh;
    /* Maximum height */
    overflow-y: auto;
    /* Enable vertical scrollbar */
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  #exams::-webkit-scrollbar,
  #assignments::-webkit-scrollbar {
    width: 8px;
  }

  #exams::-webkit-scrollbar-thumb,
  #assignments::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Transparent black */
  }

  /* Button Hover Effect with Transition */
  .button-container button {
    width: 60%;
    margin: 0.5vw 2vw;
    background-color: #4c61af;
    /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 24px;
    transition: all 0.3s ease;
    font-family: "Lexend Deca";
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .button-container {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: #ffffff4a;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
  }

  .button-container a {
    text-decoration: none;
    color: white;
  }

  /* Active Button Highlight */
  .button-container button.active {
    background-color: #ffd700;
    /* Gold */
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .button-container button:hover {
    background-color: #45a049;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  /* Exams and Assignments Sections with Shadow and Font */
  .exam-detail,
  .assignment-detail {
    min-width: 650px;
    background-color: #f1f1f175;
    margin: 20px 0;
    padding: 4px 15px 30px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Lexend Deca";
    transition: all 0.3s ease, box-shadow 0.3s ease;
    column-gap: 10px;
  }

  .exam-detail ul,
  .assignment-detail ul {
    font-size: small;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .exam-detail li,
  .assignment-detail li {
    flex: 1;
    text-align: center;
    padding: 2px;
    border-right: 1px solid black;
  }

  .exam-detail li:last-child,
  .assignment-detail li:last-child {
    border-right: none;
  }

  .exam-detail:hover,
  .assignment-detail:hover {
    transform: translateY(-10px) !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
    /* Increased shadow on hover for interactive feel */
  }

  /* Text Styles with Font */
  .exam-detail h3,
  .assignment-detail h3 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .exam-detail a,
  .assignment-detail a {
    font-family: "Lexend Deca";
    color: #000;
    text-decoration: none;
    display: block;
    transition: all 0.3s ease;
  }

  .secontent {
    margin-left: 10px;
    margin-right: 10px;
  }

  .semain-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .semain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .secontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #exams,
    #assignments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .exam-detail,
    .assignment-detail {
      min-width: 65%;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 30px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: none;
    }

    .button-container {
      width: 80vw;
      display: flex;
      flex-direction: column;
    }

    .button-container button {
      margin: 8px;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .semain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .secontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #exams,
    #assignments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .exam-detail,
    .assignment-detail {
      min-width: 65%;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 30px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: none;
    }

    .button-container {
      width: 80vw;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .semain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .secontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #exams,
    #assignments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .exam-detail,
    .assignment-detail {
      min-width: 65%;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 30px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: none;
    }

    .button-container button {
      width: 60%;
      margin: 0.5vw 2vw;
    }

    .button-container {
      width: 80vw;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .semain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .secontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #exams,
    #assignments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .exam-detail,
    .assignment-detail {
      min-width: 65%;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 30px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: none;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .semain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: 0.5vw;
      height: fit-content;
      width: 95vw;
      align-self: center;
    }

    .secontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #exams,
    #assignments {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: 1px solid black;
    }

    .exam-detail li:last-child,
    .assignment-detail li:last-child {
      border-right: none;
    }

    .button-container {
      width: 450px;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .left-navbar {
      margin-left: 0.5vw;
      height: fit-content;
      width: 300px;
      align-self: center;
    }

    .sepage-container {
      min-height: 100%;
    }

    .semain-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .secontent {
      width: 65vw;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      /* Stack items vertically */
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }

    .exam-detail ul,
    .assignment-detail ul {
      font-size: small;
      font-weight: bold;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .exam-detail li,
    .assignment-detail li {
      flex: 1;
      text-align: center;
      padding: 2px;
      border-right: 1px solid black;
    }

    .exam-detail li:last-child,
    .assignment-detail li:last-child {
      border-right: none;
    }

    .button-container {
      width: 450px;
    }
  }

  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* studentfeedback.html STYLES */
  .sfpage-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100%;
  }

  #note {
    font-family: "Lexend Deca";
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .sfmain-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sfcontent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .feedback-form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
      width: 85vw;
    }

    .sfmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .sfcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .sfmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .sfcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .sfmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .sfcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .sfmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .sfcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .sfmain-container {
      flex-wrap: wrap;
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: 1vw;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .sfcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .sfpage-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
    }

    .sfcontent {
      margin-left: auto;
      margin-right: auto;
      /* Stack items vertically */
    }

    .sfmain-container {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: center;
      width: 300px;
    }

    .footer {
      position: absolute;
      bottom: 0;
    }
  }

  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* studentcourses.html STYLES */
  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Situate the background behind the content */

    background-image: url("");
    background-size: cover;

    /* Make the background image lighter */
    filter: brightness(1);
  }

  h2 {
    font-family: "Lexend Deca";
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .left-navbar {
    height: fit-content;
    width: 300px;
    /* Fixed width */
    background-color: #528552;
    color: white;
    flex-shrink: 0;
    /* Don't allow it to shrink */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Evenly space buttons */
    /* Take up all available space */
    border-radius: 20px;
  }

  /* Left Navbar */
  .left-navbar a {
    font-family: "Lexend Deca";
    justify-content: center;
    display: flex;
    padding: 18px;
    text-align: center;
    align-content: center;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid #444;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    flex-wrap: wrap;
  }

  .left-navbar a:hover {
    background-color: #555;
  }

  .nav-button {
    flex-grow: 1;
    /* Allow buttons to grow to fill space */
    margin: 5px;
    /* Add a little margin for separation */
    padding: 10px;
    /* Add padding for better spacing */
    background-color: darkslategrey;
  }

  .scpage-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .scmain-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    flex: 1;
    justify-content: space-between;
  }

  .sccontent {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Styling for the list of enrolled courses */
  .enrolled ul {
    list-style-type: none;
    padding-left: 1vw;
    padding-right: 1vw;
    min-width: 30vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .enrolled ul li {
    font-family: "Lexend Deca";
    background-color: #ffffff89;
    margin: 8px 0;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  /* Styling for the fresh courses section */
  .sccontent form {
    margin-top: 20px;
  }

  .sccontent input[type="checkbox"] {
    margin-right: 10px;
  }

  .sccontent input[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .sccontent input[type="submit"]:hover {
    background-color: #0056b3;
  }

  .enrolled {
    display: flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    row-gap: 15;
  }

  .enrolled {
    background-color: rgba(255, 255, 255, 0.356);
    /* White with 80% opacity */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional */
  }

  .newenroll {
    background-color: rgba(255, 255, 255, 0.356);
    /* White with 80% opacity */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional */
  }

  .newenroll {
    font-family: "Lexend Deca";
    min-width: 35vw;
    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  /* Custom checkbox within .newenroll */
  .newenroll .custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .newenroll .custom-checkbox input[type="checkbox"] {
    display: none;
  }

  .newenroll .custom-checkbox label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000000;
  }

  .newenroll .custom-checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    border-radius: 4px;
    background-color: #fff;
  }

  .newenroll .custom-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #007bff;
    border: none;
  }

  .newenroll .custom-checkbox input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .note {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .scpage-container {
      height: 100%;
      flex-direction: column;
    }

    .scmain-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .sccontent {
      margin-top: 20px;
      flex-direction: column;
    }

    .enrolled,
    .newenroll {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .scpage-container {
      height: 100%;
      flex-direction: column;
    }

    .scmain-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .sccontent {
      margin-top: 20px;
      flex-direction: column;
    }

    .enrolled,
    .newenroll {
      width: 90vw;
      margin-bottom: 15px;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .scpage-container {
      height: 100%;
      flex-direction: column;
    }

    .scmain-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .sccontent {
      margin-top: 20px;
      flex-direction: column;
    }

    .enrolled,
    .newenroll {
      width: 90vw;
      margin-bottom: 15px;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    #course-overview p {
      width: 85vw;
    }

    #course-overview img {
      width: 45vw;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .scpage-container {
      height: 100%;
      flex-direction: column;
    }

    .scmain-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }
  }

  /* min-width at the end */
  @media (min-width: 1181px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .scpage-container {
      height: 100%;
      flex-direction: column;
    }

    .scmain-container {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .left-navbar {
      margin-left: 0.5vw;
      height: fit-content;
      width: 300px;
      align-self: center;
    }

    .sccontent {
      margin-top: 20px;
      flex-direction: row;
    }

    .enrolled,
    .newenroll {
      width: 30vw;
      margin-bottom: 15px;
    }

    .enrolled li {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      justify-content: center;
    }

    .enrolled ul {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
    }
  }

  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= ================================================================= */
  /* course_1.html STYLES */
  #course2 h1 {
    font-family: "Lexend Deca";
  }

  .c1main-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* Horizontal layout */
    flex: 1;
    /* Take up all available space */
    align-items: center;
    row-gap: 10px;
  }

  .c1left-navbar {
    height: fit-content;
    width: 200px;
    /* Fixed width */
    background-color: #528552;
    color: white;
    flex-shrink: 0;
    /* Don't allow it to shrink */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Evenly space buttons */
    /* Take up all available space */
    border-radius: 20px;
    margin-right: 20px;
  }

  /* Left Navbar */
  .c1left-navbar a {
    font-family: "Lexend Deca";
    justify-content: center;
    display: flex;
    padding: 18px;
    text-align: center;
    align-content: center;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid #444;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    flex-wrap: wrap;
  }

  .c1left-navbar a:hover {
    background-color: #555;
  }

  .c1nav-button {
    flex-grow: 1;
    /* Allow buttons to grow to fill space */
    margin: 5px;
    /* Add a little margin for separation */
    padding: 10px;
    /* Add padding for better spacing */
    background-color: darkslategrey;
  }

  .c1right-navbar {
    height: fit-content;
    width: 200px;
    /* Fixed width */
    background-color: #527285;
    color: white;
    flex-shrink: 0;
    /* Don't allow it to shrink */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Evenly space buttons */
    /* Take up all available space */
    border-radius: 20px;
    margin-right: 10px;
  }

  /* Left Navbar */
  .c1right-navbar a {
    font-family: "Lexend Deca";
    justify-content: center;
    display: flex;
    padding: 12px;
    text-align: center;
    align-content: center;
    text-decoration: none;
    color: white;
    border-bottom: 1px solid #444;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    flex-wrap: wrap;
  }

  .c1right-navbar a:hover {
    background-color: #555;
  }

  .c1nav-button2 {
    flex-grow: 1;
    /* Allow buttons to grow to fill space */
    margin: 15px;
    /* Add a little margin for separation */
    padding: 5px;
    /* Add padding for better spacing */
    background-color: rgb(47, 53, 79);
  }

  .c1content {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    /* Adjust the height as needed */
  }

  embed {
    border: 2px solid #ccc;
    border-radius: 8px;
    height: 700px;
    width: 60vw;
  }

  h1 {
    font-size: 45;
    font-weight: lighter;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 80vw;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 80vw;
      height: 80vh;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 80vw;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 80vw;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: 0px;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 80vw;
    }
  }

  /* min-width at the end */
  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-grow: 1;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .c1left-navbar {
      height: fit-content;
      width: 200px;
      margin-right: 2vw;
    }

    .c1right-navbar {
      height: fit-content;
      width: 200px;
      margin-right: 2vw;
    }

    .c1content {
      flex-direction: column;
    }

    embed {
      width: 60vw;
    }
  }

  /* =================================================================
    /* =================================================================
    /* course1(overview).html */
  h1 {
    font-size: 45;
    font-weight: lighter;
  }

  #course-overview img {
    width: 25vw;
    /* Sets the width to 50% of the viewport width */
    max-width: 20vw;
    /* Ensures it doesn't overflow its container */
    height: 25vh;
    /* Sets the height to 50% of the viewport height */
    max-width: 100%;
    border: 3px solid #37353567;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  #course-overview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 25px;
  }

  #course-overview {
    background-color: rgba(255, 255, 255, 0.613);
    /* White with 80% opacity */
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional */
  }

  #course-overview p,
  ul,
  li {
    font-family: "Lexend Deca";
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    #course-overview p {
      width: 90vw;
    }

    #course-overview img {
      width: 80vw;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #course-overview p {
      width: 85vw;
    }

    #course-overview img {
      width: 80vw;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
    }

    #course-overview p {
      width: 85vw;
    }

    #course-overview img {
      width: 45vw;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #course-overview p {
      width: 85vw;
    }

    #course-overview img {
      width: 45vw;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .c1main-container {
      flex-direction: column;
      flex-wrap: wrap;
    }

    .c1left-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1right-navbar {
      margin-right: auto;
      margin-left: auto;
      width: 80vw;
    }

    .c1content {
      flex-direction: column;
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #course-overview p {
      width: 85vw;
    }

    #course-overview img {
      width: 55vw;
    }
  }

  /* min-width at the end */
  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      /* Make the background image lighter */
      filter: brightness(1);
    }

    .page-container {
      height: 100%;
      flex-direction: column;
    }

    .c1main-container {
      flex-grow: 1;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .c1left-navbar {
      height: fit-content;
      width: 200px;
      margin-left: 1vw;
      margin-right: 2vw;
    }

    .c1right-navbar {
      height: fit-content;
      width: 200px;
      margin-left: 0px;
      margin-right: 1vw;
    }

    .c1content {
      flex-direction: column;
    }

    #course-overview {
      max-width: 65vw;
      margin-left: auto;
      margin-right: auto;
    }

    #course-overview p {
      width: auto;
    }

    #course-overview img {
      width: 35vw;
    }

    .footer {
      display: flex;
      flex-wrap: wrap;
    }
  }

  /* =================================================================
    /* =================================================================
    /* course1modules.html */

  .c1mcontent {
    margin-left: auto;
    margin-right: auto;
  }

  .modules {
    height: 65vh;
    /* Maximum height */
    overflow-y: auto;
    /* Enable vertical scrollbar */
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .modules::-webkit-scrollbar {
    width: 8px;
  }

  .modules::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Transparent black */
    border-radius: 4px;
  }

  .module-detail {
    min-width: 30vw;
    background-color: #f1f1f175;
    margin: 20px 0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Lexend Deca";
    transition: all 0.3s ease, box-shadow 0.3s ease;
    column-gap: 10px;
  }

  .module-detail:hover {
    transform: translateY(-10px) !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
    /* Increased shadow on hover for interactive feel */
  }

  .module-detail a {
    text-decoration: none;
    color: #000;
  }

  #hmodule {
    text-align: center;
    align-items: center;
  }

  @media (min-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .c1main-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .c1left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
      /* To ensure padding doesn't affect width */
    }

    .c1right-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
      /* To ensure padding doesn't affect width */
    }

    .c1content {
      flex-direction: column;
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .c1mcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .modules {
      width: 100vw;
      min-height: 100%;
      overflow-y: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .module-detail {
      min-width: 80vw;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 30px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .c1main-container {
      row-gap: 35px;
      flex-direction: row;
      /* Stack items vertically */
    }

    .c1left-navbar {
      height: fit-content;
      width: 200px;
      margin-left: 1vw;
      margin-right: 2vw;
    }

    .c1right-navbar {
      height: fit-content;
      width: 200px;
      margin-left: 0px;
      margin-right: 1vw;
    }

    .c1mcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .modules {
      width: 40vw;
      height: 75vh;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .module-detail {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      width: 8vw;
      background-color: #f1f1f175;
      margin: 30px 0;
      padding: 4px 15px 10px 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: "Lexend Deca";
      transition: all 0.3s ease, box-shadow 0.3s ease;
      column-gap: 10px;
    }
  }

  /* =================================================================
    /* =================================================================
    /* course1grades.html */

  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0077b5;
    /* LinkedIn blue */
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  .button > a {
    text-decoration: none;
    color: white;
  }

  .grades-table {
    width: 65vw;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.4);
    overflow: hidden;
    /* Add this line */
  }

  .grades-table thead {
    background-color: #404040;
    color: #ffffff;
  }

  .grades-table thead tr:first-child th:first-child {
    border-top-left-radius: 20px;
  }

  .grades-table thead tr:first-child th:last-child {
    border-top-right-radius: 20px;
  }

  .grades-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
  }

  .grades-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
  }

  .grades-table-row {
    transition: background-color 0.3s ease;
  }

  .grades-table-row:hover,
  .grades-table-row:nth-child(even):hover {
    background-color: #92615aa5;
  }

  .grades-table-row > td,
  th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  /* Zebra striping */
  .grades-table-row:nth-child(even) {
    background-color: #f2f2f2;
  }

  .grades-table-header {
    background-color: #404040;
    color: white;
  }

  /* ======================================================================================================*/
  .bar-container {
    width: 500px;
    height: 300px;
    margin: auto;
    background-color: #fff;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bar {
    display: inline-block;
    width: 100%;
    height: 10px;
    background-color: blue;
    margin: 20px;
    position: relative;
  }

  .marks {
    display: inline-block;
    width: 90%;
    height: 10px;
    background-color: green;
    /* margin: 20px; */
  }

  body {
    background-color: gray;
  }

  .mean {
    display: inline-block;
    width: 5px;
    height: 10px;
    background-color: red;
    position: absolute;
    left: 75%;
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* before login styles */

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* program coordinator styles */

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* admin styles */
  /* admin_home.html */
  .ahpage_container {
    min-height: 100%;
    /* takes full viewport height */
  }

  .ahmain-container {
    display: flex;
    flex-direction: row;
  }

  .ahcontent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .ahmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .ahcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .ahmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .ahcontent {
      height: 20vw;
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .ahmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .ahcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    #note {
      display: flex;
      flex-wrap: wrap;
    }

    .ahmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .ahcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .ahmain-container {
      row-gap: 35px;
      flex-direction: column;
      /* Stack items vertically */
    }

    .left-navbar {
      margin-left: 1vw;
      margin-right: auto;
      width: 90vw;
      box-sizing: border-box;
      /* To ensure padding doesn't affect width */
    }

    .ahcontent {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .ahcontent {
      margin-left: auto;
      margin-right: auto;
      /* Stack items vertically */
    }

    .ahpage_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
    }

    .ahmain-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: center;
      width: 300px;
    }

    .foot {
      display: flex;
      position: relative;
      bottom: 0px;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* admin styles */
  /* manage_course.html */
  .amcpage_container {
    display: flex;
    flex-direction: column;
  }

  .amcmain-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .amccontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .form {
    margin: 20px;
    display: flex;
    height: auto;
  }

  .admin_form {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  #admc {
    width: auto;
    height: auto;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
  }

  #admc legend {
    font-family: "Lexend Deca";
    font-size: 25px;
  }

  #admc input {
    text-align: center;
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
  }

  #admc select {
    text-align: center;
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
  }

  #admc textarea {
    border-radius: 8px;
    font-family: "Lexend Deca";
  }

  #admc label {
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
    cursor: pointer;
  }

  .amcgrades-table {
    width: 65vw;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.4);
    overflow: hidden;
    /* Add this line */
  }

  .amcgrades-table thead {
    background-color: #404040;
    color: #ffffff;
  }

  .amcgrades-table thead tr:first-child th:first-child {
    border-top-left-radius: 20px;
  }

  .amcgrades-table thead tr:first-child th:last-child {
    border-top-right-radius: 20px;
  }

  .amcgrades-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
  }

  .amcgrades-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
  }

  .amcgrades-table-row {
    transition: background-color 0.3s ease;
  }

  .amcgrades-table-row:hover,
  .grades-table-row:nth-child(even):hover {
    background-color: #92615aa5;
  }

  .amcgrades-table-row > td,
  th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  /* Zebra striping */
  .amcgrades-table-row:nth-child(even) {
    background-color: #f2f2f2;
  }

  .amcgrades-table-header {
    background-color: #404040;
    color: white;
  }

  .amcgrades-table-row button[type="submit"] {
    font-family: "Lexend Deca";
    background-color: #007bff;
    /* Blue */
    color: white;
    padding: 8px 10px;
    font-size: 13px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  button[type="submit"]:hover {
    background-color: #1673d5;
    /* Darker blue */
    transform: translateY(-2px);
  }

  .nuser {
    width: auto !important;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #admc {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .amcgrades-table {
      width: 90vw;
    }

    .amccontent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #admc {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .amcgrades-table {
      width: 90vw;
    }

    .amccontent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }

    .amctable {
      width: auto;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcpage_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
    }

    .amcmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #admc {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }

    .amcgrades-table {
      width: 70vw;
    }

    .amccontent {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #admc {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .amcgrades-table {
      width: 90vw;
    }

    .amccontent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    #admc {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .amcgrades-table {
      width: 90vw;
    }

    .amccontent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .amcmain-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: center;
      width: 300px;
      margin-left: 1vw;
    }

    .amccontent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .footer {
      position: absolute;
      top: 100%;
      bottom: 0;
    }

    #admc {
      width: 10vw;
    }

    .amcgrades-table {
      width: 70vw;
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Quality assurance Officer styles */
  /* qao.html */

  .qapage-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .qamain-container {
    display: flex;
    flex-direction: row;
  }

  .qacontainer {
    display: flex;
    flex-direction: column;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }

  #info p,
  #info h3,
  #info ol {
    font-family: "Lexend Deca";
  }

  @media (max-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qamain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 321px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qamain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qamain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qamain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qamain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qapage-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .qamain-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: center;
      width: 300px;
      margin-left: 1vw;
    }

    .qacontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 60vw;
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Quality assurance Officer styles */
  /* qaorecommendations.html */

  .qrecpage-container {
    display: flex;
    flex-direction: column;
  }

  .qrecmain-container {
    display: flex;
    flex-direction: row;
  }

  .qreccontainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qrecmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qreccontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qrecmain-container {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qreccontainer {
      margin-top: 5vw;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: auto;
    }

    .qaorec {
      width: 60vw;
    }

    #description {
      margin-left: auto;
      margin-right: auto;
      width: 60vw !important;
      /* or any other percentage or viewport-based width */
      resize: both;
      /* Allows resizing both vertically and horizontally */
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qrecmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .qreccontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
    }

    #description {
      width: 60vw !important;
      /* or any other percentage or viewport-based width */
      resize: both;
      /* Allows resizing both vertically and horizontally */
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .qrecmain-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: center;
      width: 300px;
      margin-left: 1vw;
    }

    .qreccontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
    }

    #description {
      width: 40vw !important;
      /* or any other percentage or viewport-based width */
      resize: both;
      /* Allows resizing both vertically and horizontally */
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Quality assurance Officer styles */
  /* qareports.html */
  .pcrmain-container {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }

  .right-content {
    padding: 20px;
    flex-grow: 1;
  }

  /* PC_container styles */
  .PC_container {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }

  /* Collaboration-box styles */
  .collaboration-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .collaboration-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #ffffff90;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add box shadow */
  }

  /* Collaboration-container styles */
  .collaboration-container {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    /* Add margin top */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Add box shadow */
  }

  .scrollable_container {
    background-color: #f0f0f0;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: auto;
  }

  /* Table styles */
  #data-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  #data-table thead {
    position: sticky;
    top: 0;
    background-color: #007bff;
    color: #fff;
    z-index: 1;
  }

  #data-table th,
  #data-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }

  #data-table tbody {
    max-height: 200px;
    /* Adjust this value as needed */
    overflow-y: auto;
  }

  /* Loading animation styles */

  #data-table.loading tbody tr {
    animation: loading 1.5s infinite;
  }

  /* report stles */

  .PC_container {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    /* Adjust the gap between the cards */
    padding: 16px;
    /* Adjust the padding around the container */
  }

  /* Add a hover effect to the cards */
  .card {
    background-color: rgba(255, 255, 255, 0.683);
    padding: 20px;
    margin: 10px;
    margin-left: 12px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .card:hover {
    transform: translateY(-5px);
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  h2 {
    margin: 0 0 8px;
    /* Adjust the margin around the course name */
    font-size: 18px;
    /* Adjust the font size of the course name */
  }

  p {
    font-family: "Lexend Deca";
    margin: 0;
    /* Adjust the margin around the pass percentage */
    font-size: 16px;
    /* Adjust the font size of the pass percentage */
  }

  .PC_container {
    margin-top: 5vw;
    padding: 20px;
    width: 70vw;
    border-radius: 20px;
    margin-right: auto;
    margin-left: auto;
  }

  .pcrpage_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
  }

  .card a {
    text-decoration: none;
    color: #000;
  }

  @media (max-width: 319px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .PC_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 320px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .PC_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 375px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .PC_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 768px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .PC_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 912px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-navbar {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }

    .PC_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 1024px) {
    body::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      /* Situate the background behind the content */

      background-image: url("");
      background-size: cover;
      background-position: center;
    }

    .pcrmain-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .left-navbar {
      height: fit-content;
      /* To ensure padding doesn't affect width */
      align-self: flex-start;
      width: 300px;
      margin-left: 1vw;
    }

    .PC_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 40vw;
    }

    .right-content {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Quality assurance Officer styles */
  /* qamonitor.html */

  @media (min-width: 319px) {
    .special-form {
      min-width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 1024px) {
    .qamonpage_container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .left-navbar {
      margin-top: 5vw;
    }

    .qamonmain-container {
      display: flex;
      flex-wrap: row;
      height: 100%;
    }

    .foot {
      display: flex;
      position: relative;
      bottom: 0px;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Chat styles */
  /* chat.html */
  .chpage-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100%;
  }

  .chmain-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .searchcontent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .chat-container {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #f1f1f175;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease, box-shadow 0.3s ease;
    font-family: "Lexend Deca";
  }

  .old-chats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: 30px;
    }
  }

  .old-chats-header input {
    height: 20px;
  }

  .chat-template {
    display: flex;
    flex-direction: column;
    background: #ffffff85;
    margin-bottom: 10px;
    padding: 10px;
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-container input {
    /* Add your styles here */
    text-align: center;
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
  }

  @media (max-width: 319px) {
    .chatheader {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chatdropdown {
        width: 100%;
        margin-right: 0px;
      }

      .chatsearch {
        width: 100%;
      }
    }

    .chat-container {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }

    .chpage-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
      overflow: auto;
    }

    .chmain-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .foot {
      position: relative;
      bottom: 0;
    }
  }

  @media (min-width: 320px) {
    .chatheader {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chatdropdown {
        width: 100%;
        margin-right: 0px;
      }

      .chatsearch {
        width: 100%;
      }
    }

    .chat-container {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }

    .chpage-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
      overflow: auto;
    }

    .chmain-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .foot {
      position: relative;
      bottom: 0;
    }
  }

  @media (min-width: 769px) {
    .chatheader {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chatdropdown {
        width: 100%;
        margin-right: 0px;
      }

      .chatsearch {
        width: 100%;
      }
    }

    .chat-container {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }

    .chpage-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
      overflow: auto;
    }

    .chmain-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .foot {
      position: relative;
      bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    .chatheader {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chatdropdown {
        width: 100%;
        margin-right: 0px;
      }

      .chatsearch {
        width: 100%;
      }
    }

    .chat-container {
      width: 30vw;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 5vw;
      margin-right: auto;
    }

    .chpage-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 100%;
    }

    .chmain-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .bar-container {
    height: 20vh;
    max-width: 70vw;
    min-width: 60vw;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .bar {
    display: inline-block;
    width: 100%;
    height: 10px;
    background-color: blue;
    margin: 20px;
    position: relative;

    .mean-num {
      left: 80%;
    }

    .marks {
      left: 89%;
    }

    .min {
      left: 0%;
      top: -30px;
    }

    .max {
      left: 95%;
      top: -30px;
    }
  }

  .marks {
    display: inline-block;
    width: 90%;
    height: 10px;
    background-color: green;
    /* margin: 20px; */
  }

  .bar-page {
    width: 100%;
    height: 100%;
    background-color: gray;
  }

  .mean {
    display: inline-block;
    width: 5px;
    height: 10px;
    background-color: red;
    position: absolute;
    left: 80%;
  }

  .mean-marks {
    display: inline-block;
    /* width:px; */
    height: 10px;
    /* background-color: red;  */
    position: absolute;
    margin-top: 10px;
    left: 80%;
  }

  .marks-letter {
    display: inline-block;
    /* width:; */
    height: 10px;
    /* background-color: green; */
    /* margin: 20px; */
    position: absolute;
    left: 89%;
    margin-top: 10px;
  }

  .marks-min {
    display: inline-block;
    /* width:; */
    height: 10px;
    /* background-color: green; */
    /* margin: 20px; */
    position: absolute;
    left: 89%;
    margin-top: 10px;
  }

  .marks-letter {
    display: inline-block;
    /* width:; */
    height: 10px;
    /* background-color: green; */
    /* margin: 20px; */
    position: absolute;
    left: 89%;
    margin-top: 10px;
  }

  .numbers {
    display: inline-block;
    /* width:; */
    height: 10px;
    /* background-color: green; */
    /* margin: 20px; */
    position: absolute;
    margin-top: 10px;
  }

  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* ======================================================================================================*/
  /* Forgot Pasword styles */

  .fp-container {
    /* width: 40%; */
    margin: auto;
    padding: 20px;
  }

  button.reset-button,
  button.resend-button {
    display: block;
    margin: 20px auto;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    transition: background-image 0.3s, background-position 0.3s;
    /* Added transition */
    background-image: linear-gradient(90deg, #007bff, #0056b3);
    /* Initial gradient background */
    background-size: 200% 100%;
    /* Initial background size */
    background-position: 100% 0;
    /* Initial background position (to the right) */
  }

  button.reset-button:hover,
  button.resend-button:hover {
    background-position: 0 0;
    /* Change background position on hover (to the left) */
  }

  button.reset-button:focus,
  button.resend-button:focus {
    outline: none;
    /* Remove the default focus outline */
  }

  .fp-login-button,
  .fp-signup-button,
  .fp-contact-button,
  .fp-service-button {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .fp-login-button:hover,
  .fp-signup-button:hover,
  .fp-contact-button:hover,
  .fp-service-button:hover {
    background-color: #fff;
    color: #333;
    transform: translateY(-2px);
  }

  .header-buttons,
  .footer-buttons {
    display: flex;
    /* gap: 10px; */
  }

  /* Login styles*/
  .login-container {
    /* width: 40%; */
    margin: auto;
    padding: 20px;
  }

  .remember-me {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 20px;
  }

  .remember-me label {
    margin-left: 10px;
  }

  .forgot-password {
    padding-left: 20px;
    margin-top: 10px;
  }

  button.success-signin-button {
    display: block;
    margin: 20px auto;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s;
  }

  button.success-signin-button:hover {
    background-color: #0056b3;
  }

  .llogin-button,
  .lsignup-button,
  .lcontact-button,
  .lservice-button {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .loginpage-head header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    /* Add padding to the header */
  }

  .loginpage-head h1 {
    margin: 0;
    flex-grow: 1;
    text-align: center;
  }

  .loginpage-header-buttons {
    display: flex;
    gap: 10px;
    /* Spacing between buttons */
  }

  .llogin-button:hover,
  .lsignup-button:hover,
  .lcontact-button:hover,
  .lservice-button:hover {
    background-color: #fff;
    color: #333;
    transform: translateY(-2px);
  }

  .loginpage-header-buttons,
  .loginpage-footer-buttons {
    display: flex;
    gap: 10px;
  }

  /* Homepage styles */
  .home-container {
    border-radius: 15px;
    display: flex;
    width: 80%;
    margin: 20px auto;
    box-shadow: 0px 4px 2px -2px gray;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.634);
  }

  .reversed {
    flex-direction: row-reverse;
  }

  .triple-images {
    justify-content: space-around;
  }

  .home-image-container {
    width: 50%;
    text-align: center;
    border-radius: 15px;
  }

  .home-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 3px 3px 5px #888888;
  }

  .home-text-container {
    display: flex;
    width: 50%;
    padding: 20px;
    border-radius: 15px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  .aboutus-right-text h3 {
    align-items: center;
    text-align: center;
    font-family: "Lexend Deca";
  }

  @media (min-width: 320px) {
    .homepage_container {
      display: flex;
      flex-direction: column;
      width: 100vw;
    }

    .home-text-container {
      display: flex;
      width: 90vw;
      padding: 20px;
      border-radius: 15px;
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  /* services css*/

  #info select {
    /* text-align: center; */
    border-radius: 8px;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Lexend Deca";
    width: -webkit-fill-available;
  }

  .ser-service-description {
    background-color: rgba(255, 255, 255, 0.3);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    /* Added transition for hover effect */
  }

  .ser-service-description:hover {
    transform: translateY(-5px);
    /* Move the section up slightly on hover */
    background-color: #b8d0d1;
  }

  .ser-login-container h2,
  .ser-login-container h3,
  .ser-login-container p {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Monotype Corsiva", cursive;
  }

  .ser-login-container p {
    margin-top: 20px;
    padding-left: 20px;
  }

  .ser-login-container input[type="text"],
  .ser-login-container input[type="password"] {
    display: block;
    width: 90%;
    padding: 10px 20px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  .ser-login-container input[type="text"]:hover,
  .ser-login-container input[type="password"]:hover {
    border-color: #007bff;
  }

  .ser-remember-me {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 20px;
  }

  .ser-remember-me label {
    margin-left: 10px;
  }

  .ser-forgot-password {
    padding-left: 20px;
    margin-top: 10px;
  }

  .ser-service-description {
    background-color: rgba(255, 255, 255, 0.641);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }

  .ser-service-description:hover {
    transform: translateY(-5px);
    background-color: #b8d0d1;
  }

  .ser-contact {
    background-color: #fff;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /*  contact form styles */
  .contact-info {
    white-space: nowrap;
    overflow: scroll;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    /* Added transition for hover effect */
  }

  .contact-form {
    width: 40%;
    margin: auto;
    padding: 20px;
  }

  /* Apply the hover effect to both contact-info and contact-form sections */
  .contact-info:hover {
    transform: translateY(-5px);
    /* Move the section up slightly on hover */
    background-color: #eff1f1;
    /* filter: blur(3px);  Add a blur effect to the section on hover */
  }

  .contact-info h2 {
    margin-top: 0;
    font-family: "Monotype Corsiva", cursive;
  }

  .contact-info ul {
    list-style-type: none;
    padding: 0;
  }

  .contact-info ul li {
    margin-bottom: 10px;
  }

  .contact-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 2px solid #ccc;
    border-radius: 12px;
  }

  /* signup css */
  .signup-container {
    width: 40%;
    margin: auto;
    padding: 20px;
  }

  .filldetails {
    width: 30vw !important;
    font-family: "Lexend Deca" !important;
  }

  .account-info-signup {
    display: flex;

    align-items: center;
  }

  .account-info-signup a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }

  .account-info-signup a:hover {
    color: #0056b3;
  }

  .signup1-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  @media (max-width: 319px) {
    .signup-container {
      width: 95%;
    }

    .filldetails {
      width: 90vw !important;
    }
  }

  /* Styles for screens between 320px and 374px */
  @media (min-width: 320px) and (max-width: 374px) {
    .signup-container {
      width: 95%;
    }

    .filldetails {
      width: 30vw !important;
    }
  }

  /* Styles for screens between 375px and 767px */
  @media (min-width: 375px) and (max-width: 767px) {
    .signup-container {
      width: 95%;
    }

    .filldetails {
      width: 75vw !important;
    }

    .for_phone {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* Styles for screens between 768px and 911px */
  @media (min-width: 768px) and (max-width: 911px) {
    .signup-container {
      width: 70%;
    }

    .filldetails {
      width: 50vw !important;
    }
  }

  /* Styles for screens between 912px and 1023px */
  @media (min-width: 912px) and (max-width: 1023px) {
    .signup-container {
      width: 60%;
    }

    .filldetails {
      width: 30vw !important;
    }
  }

  .signselect {
    width: auto !important;
  }

  /* Styles for screens 1024px and above */
  @media (min-width: 1024px) {
    .signup-container {
      width: auto;
    }
  }

  /* About Us styles */

  .aboutus-aboutus-container {
    display: flex;
    flex-flow: column;
  }

  .aboutus-aboutus-container h1 {
    font-family: "Lexend Deca";
  }

  .aboutus-main-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
  }

  /* For medium devices */
  @media (min-width: 768px) and (max-width: 991px) {
    .aboutus-sub-container {
      flex-direction: row;
    }
  }

  /* For large devices */
  @media (min-width: 992px) {
    .aboutus-sub-container {
      flex-direction: row;
    }
  }

  .aboutus-sub-container:hover,
  .aboutus-bottom_para:hover,
  .aboutus-mid-text-container:hover {
    background-color: #eff1f1;
  }

  .aboutus-sub-container {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    background-color: transparent;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #ccc;
  }

  .aboutus-bottom_para {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    background-color: transparent;

    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #ccc;
  }

  .aboutus-left-text {
    flex-direction: row-reverse;
  }

  .aboutus-main-container .aboutus-sub-container {
    background-color: rgba(255, 255, 255, 0.644);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .aboutus-left-image,
  .aboutus-right-image {
    max-width: 350px;
    max-height: 350px;
    object-fit: cover;
    margin-right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .aboutus-right-text {
    flex: 1;
  }

  .aboutus-gap-between-text-and-separate {
    margin-top: 60px;
  }

  .aboutus-left-image-bottom {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1px;
    background-color: transparent;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #ccc;
    max-width: 20%;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contact-form-container {
    display: flex;
    flex-direction: column;
    /* flex-direction: column; */
  }

  /* For small devices */
  @media (max-width: 767px) {
    .aboutus-sub-container {
      flex-direction: column !important;
    }

    .aboutus-left-image,
    .aboutus-right-image {
      width: 200px;
      margin: 0px !important;
    }

    .contact-form-container {
      /* display: flex; */
      flex-direction: column;
      height: 100%;
    }

    .home-container {
      flex-direction: column;
      align-items: center;
      /* width: 80%;
        margin: 20px auto;
        box-shadow: 0px 4px 2px -2px gray;
        padding: 20px; */
    }
  }

  @media (min-width: 1024px) {
    .contact-form-container {
      /* display: flex; */
      flex-direction: column;
      height: 100%;
    }
  }

  /* PC Styles */
  .add-button,
  .delete-button {
    background-color: #4caf50;
    /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .delete-button {
    background-color: #f44336;
    /* Red */
  }

  .program-goals,
  .institution-goals,
  .department-vision {
    margin: 20px;
    padding: 20px;
    background-color: #e0e2e8;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .program-goals:hover,
  .institution-goals:hover,
  .department-vision:hover,
  .program-goals-container:hover {
    margin: 20px;
    padding: 20px;
    background-color: #e0e2e8;
  }

  .right-content {
    padding: 20px;
    flex-grow: 1;
  }

  .PCgoals_container {
    background-color: #ffffff83;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .program-goals-container {
    background-color: #e0e2e8;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .program-goals-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .program-goals-container p {
    text-align: left;
    line-height: 1.5;
  }

  .program-goals-container ul {
    list-style-type: decimal;
    padding-left: 20px;
  }

  .collaboration-container:hover {
    transform: translateY(-2px);
  }

  .pcgoals-main-container {
    display: flex;
  }

  .PC_container-card-grid {
    /* display: grid; */
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    gap: 20px;
    /* Add gap between cards */
    max-height: 400px;
    /* Set a maximum height for vertical scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
    flex-wrap: wrap;
    /* Allow cards to wrap within the grid */
    padding-bottom: 10px;
    /* Add some bottom padding for spacing */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
  }

  .card-enquiries {
    background-color: rgba(255, 255, 255, 0.617);
    padding: 20px;
    width: calc(50% - 10px);
    /* Limit each card to half of the grid container width */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 20px;
    /* Add bottom margin for spacing between rows */
    transition: transform 0.2s, box-shadow 0.2s;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
  }

  /* Style the student info within the card */
  .student-info-enquiries {
    margin-top: 10px;
  }

  /* Add a hover effect to the cards */
  .card-enquiries:hover {
    transform: translateY(-5px);
    background-color: #dbdec8;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* For small devices */
  @media (max-width: 767px) {
    .pcgoals-main-container {
      flex-direction: column;
    }
  }

  .colab-list-container {
    background-color: #f0f0f0a0;
    padding: 10px;
    border-radius: 10px;
  }

  .card-row-enquiries {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* ===================================================================================================================== */

  /* CHAT ICON */
  .chat-icon {
    position: fixed;
    /* Fixed position */
    bottom: 60px;
    /* Above the footer */
    right: 20px;
    /* Right side of the page */
    z-index: 1000;
    /* Above other elements */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle box shadow */
    border-radius: 50%;
    /* Fully rounded to make it look like an icon */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transition for hover effect */
  }

  .login-button-container {
    .my-button {
      font-family: "Lexend Deca";
      background-color: #007bff;
      color: white;
      padding: 14px 28px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;
      margin: 5px;
    }

    .my-button:hover {
      background-color: #1673d5;
      transform: translateY(-2px);
    }

    .my-button:active {
      background-color: #0153ab;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transform: translateY(1px);
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 5px;
      z-index: 1;
    }

    .option {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .option:hover {
      background-color: #f0f0f0;
    }
  }
}

.fp-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 100px;
}

.fp-form {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

.fp-heading {
  font-size: 12px;
  text-align: center;
}

.fp-form > label {
  font-size: 14px;
  margin-right: 20px;
}

.fp-form > input {
  padding: 12px;
  width: 100%;
  border: 2px solid black;
  border-radius: 10px;
}

.register-button {
  display: block;
  margin: 20px auto;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  transition: background-image 0.3s, background-position 0.3s;
  /* Added transition */
  background-image: linear-gradient(90deg, #007bff, #0056b3);
  /* Initial gradient background */
  background-size: 200% 100%;
  /* Initial background size */
  background-position: 100% 0;
  /* Initial background position (to the right) */
}

.reset-button {
  display: block;
  margin: 20px auto;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  transition: background-image 0.3s, background-position 0.3s;
  /* Added transition */
  background-color: #007bff;
  /* Initial gradient background */
  background-size: 200% 100%;
  /* Initial background size */
  background-position: 100% 0;
  /* Initial background position (to the right) */
}

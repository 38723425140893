table {
    border-collapse: collapse;
    width: 100%;
    margin-left: 20px;
    margin-top: 15px;
    height: 50%;
 }    
 th, td {
    text-align: left;
    border-bottom: solid ;
    padding: 5px;
 }
 tr:hover {background-color: #b6b6b6;}